<template>
  <b-overlay :show="showLoading">
    <b-row>
      <b-col cols="6">
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              1
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>پوچ</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeOne.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeOne)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6" >
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              2
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>کوپن</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeTow.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-form-group
              label="انتخاب کوپن"
              label-for="description"
              class="mb-2"
          >
            <v-select
                v-model="prizeTow.couponId"
                :options="coupons"
                label="code"
                :reduce="code => code.couponId"
                :clearable="false"
                input-id="productCategoryId"
            />
          </b-form-group>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeTow)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              3
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>شارژ کیف پول</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeThree.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-form-group
              label="مبلغ (تومان)"
              label-for="description"
              class="mb-2"
          >
            <b-form-input
                id="Title"
                v-model="prizeThree.walletCredit"
            />
          </b-form-group>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeThree)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              4
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>پوچ</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeFour.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeFour)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              5
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>شارژ کیف پول</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeFive.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-form-group
              label="مبلغ (تومان)"
              label-for="description"
              class="mb-2"
          >
            <b-form-input
                v-model="prizeFive.walletCredit"
            />
          </b-form-group>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeFive)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              6
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>دوباره</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeSix.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeSix)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              7
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>مانترا</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeSeven.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeSeven)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6" >
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              8
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>کوپن</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeEight.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-form-group
              label="انتخاب کوپن"
              label-for="description"
              class="mb-2"
          >
            <v-select
                v-model="prizeEight.couponId"
                :options="coupons"
                label="code"
                :reduce="code => code.couponId"
                :clearable="false"
                input-id="productCategoryId"
            />
          </b-form-group>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeEight)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              9
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>ملاقات با فاطمه سادات</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeNine.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeNine)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              10
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>پوچ</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeTen.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeTen)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              11
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>شارژ کیف پول</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeEleven.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-form-group
              label="مبلغ (تومان)"
              label-for="description"
              class="mb-2"
          >
            <b-form-input
                id="Title"
                v-model="prizeEleven.walletCredit"
            />
          </b-form-group>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeEleven)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

      <b-col cols="6" >
        <b-card style="min-height: 20rem">
          <h1>
            جایزه شماره
            <b-badge
                pill
                variant="light-primary">
              12
            </b-badge>
          </h1>

          <div class="row">
            <b-col cols="6">
              <b-form-group
                  label="عنوان جایزه"
                  label-for="title"
                  class="mb-2"
              >
                <h3>کوپن</h3>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                  label="احتمال (درصدی)"
                  label-for="probability"
                  class="mb-2"
              >
                <b-form-input
                    v-model="prizeTwelve.probability"
                />
              </b-form-group>
            </b-col>
          </div>

          <b-form-group
              label="انتخاب کوپن"
              label-for="description"
              class="mb-2"
          >
            <v-select
                v-model="prizeTwelve.couponId"
                :options="coupons"
                label="code"
                :reduce="code => code.couponId"
                :clearable="false"
                input-id="productCategoryId"
            />
          </b-form-group>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="updatePrize(prizeTwelve)"
          >
            ویرایش
          </b-button>
        </b-card>
      </b-col>

    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BFormTextarea, BFormGroup
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import {quillEditor} from 'vue-quill-editor'

import {PostCreateRequest} from "@/libs/Api/Post";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {StaticPageGetByTypeRequest, StaticPageUpdateRequest} from "@/libs/Api/StaticPage";
import {PrizeGetAllRequest, PrizeUpdateRequest} from "@/libs/Api/Prize";
import {CouponGetAllRequest} from "@/libs/Api/Coupon";
import vSelect from "vue-select";

export default {
  name: "contactUs",
  title: 'شانسی - پنل ادمین مکس ',
  data() {
    return {
      prizes: [],
      coupons:[],
      showLoading: false,
      prizeOne: null,
      prizeTow: null,
      prizeThree: null,
      prizeFour: null,
      prizeFive: null,
      prizeSix: null,
      prizeSeven: null,
      prizeEight: null,
      prizeNine: null,
      prizeTen: null,
      prizeEleven: null,
      prizeTwelve: null,
    }
  },
  async created() {
    await this.getPrizes()
    await this.getAllCoupons()
  },
  methods: {
    async getPrizes() {
      let _this = this;
      _this.showLoading = true;

      let data = {
        pageNumber: 0,
        count: 0
      }

      let prizeGetAllRequest = new PrizeGetAllRequest(_this);
      prizeGetAllRequest.setParams(data);
      await prizeGetAllRequest.fetch(function (content) {
        _this.prizes = content.prizes;
        _this.prizeOne = _this.prizes[0]
        _this.prizeTow = _this.prizes[1]
        _this.prizeThree = _this.prizes[2]
        _this.prizeFour = _this.prizes[3]
        _this.prizeFive = _this.prizes[4]
        _this.prizeSix = _this.prizes[5]
        _this.prizeSeven = _this.prizes[6]
        _this.prizeEight = _this.prizes[7]
        _this.prizeNine = _this.prizes[8]
        _this.prizeTen = _this.prizes[9]
        _this.prizeEleven = _this.prizes[10]
        _this.prizeTwelve = _this.prizes[11]
        _this.showLoading = false;
      }, function (error) {
        _this.showLoading = false;
        console.log(error)
      })
    },
    async updatePrize(param) {
      let _this = this;

      _this.showLoading = true;

      let prizeUpdateRequest = new PrizeUpdateRequest(_this);
      prizeUpdateRequest.setParams(param);
      await prizeUpdateRequest.fetch(function (content) {
        _this.showLoading = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.getPrizes();
      }, function (error) {
        _this.showLoading = false;
        console.log(error)
      })
    },
    async getAllCoupons(){
      let  _this = this;
      _this.showLoading = true;

      let data={
        pageNumber:1,
        count:1000,
      }

      let couponGetAllRequest = new CouponGetAllRequest(_this);
      couponGetAllRequest.setParams(data);
      await couponGetAllRequest.fetch(function (content){
        _this.coupons = content.coupons;
        _this.showLoading = false;
      },function (error){
        _this.showLoading = false;
        console.log(error)
      })
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    quillEditor,
    BFormTextarea,
    BFormGroup,
    vSelect,
  },
}
</script>

<style scoped>

</style>