import WebServiceRequest from '../Api/WebServiceRequest'

class PrizeCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Prize/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class PrizeDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Prize/Delete')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class PrizeGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Prize/GetAll');
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class PrizeGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Prize/Get')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class PrizeGetByUserIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Prize/GetByUserId')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class PrizeUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Prize/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class PrizeGetRandomPrizeRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Prize/GetRandomPrize')
    }
}

export {
    PrizeCreateRequest,
    PrizeDeleteRequest,
    PrizeGetAllRequest,
    PrizeGetRequest,
    PrizeUpdateRequest,
    PrizeGetRandomPrizeRequest
};
